/*-------------------------------------------*\
    Preloader style
\*-------------------------------------------*/

.main-preloader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(100vh - 56px);
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    z-index: 99999;
    box-sizing: border-box;
    overflow-x: hidden;
    &.fixed {
        height: 100%;
    }
    .pulse {
        position: relative;
        left: 50%;
        top: 50vh;
        margin-left: -40px;
        margin-top: -40px;
        &:after, &:before {
            content: '';
            position: absolute;
            width: 80px;
            height: 80px;
            border: 5px solid #2290E0;
            border-radius: 50%;
        }
        &:before {
            animation: pulse-outer .8s ease-in infinite;
        }
        &:after {
            animation: pulse-inner .8s linear infinite 
        }
    }
  }
  
  @keyframes pulse-outer {
    0% {
        opacity: 1
    }
    50% {
        opacity: .5
    }
    100% {
        opacity: 0
    }
  }
  
  @keyframes pulse-inner {
    0% {
        opacity: 0;
        transform: scale(0)
    }
    100% {
        opacity: 1;
        transform: scale(1)
    }
  }
  
  .preorder {
    position: absolute;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -25px;
        margin-top: -25px;
    }
  }
  
/*-------------------------------------------*\
    End Preloader style
\*-------------------------------------------*/