 $defaultColor: #50BF34;
 $hoverColor: #2ea210;

body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    color: #333333;
    strong {
        font-weight: 600;
    }
}
// button {
//     &.MuiButton-colorInherit {
//         padding: 12px 30px;
//         font-size: 20px;
//         line-height: 26px;
//         text-transform: none;
//         font-weight: 600;
//         border-radius: 8px;
//         background-color: rgba(196, 196, 196, 0.2);
//     }
//     &.MuiButton-containedPrimary {
//         padding: 12px 30px;
//         font-size: 20px;
//         line-height: 26px;
//         text-transform: none;
//         font-weight: 600;
//         border-radius: 8px;
//         background-color: $defaultColor;
//         color: #fff;
//         &:hover {
//             background-color: $hoverColor;
//         }
//     }
//     &.tableLinkBlue {
//         color: #007AFF;
//         text-transform: none;
//         font-weight: 400;
//     }
// }
button {
    &.MuiButton-containedPrimary {
        background-color: #31C85A;
        text-transform: none;
        height: 40px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        &:hover {
            background: #1DB446;
        }
    }
    &.MuiButton-containedSecondary {
        background-color: #FF3B30;
        text-transform: none;
        height: 40px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
    }
    &.MuiButton-outlined {
        text-transform: none;
        height: 40px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
    }
    &.MuiButton-colorInherit {
        text-transform: none;
        height: 40px;
        background-color: rgba(196, 196, 196, 0.2);
        font-size: 14px;
        font-weight: 600;
    }
    &.tableLinkBlue {
        color: #007AFF;
        text-transform: none;
        font-weight: 400;
    }
    &.tableLinkRed {
        color: hsl(0, 100%, 50%);
        text-transform: none;
        font-weight: 400;
    }
}
table {
    .MuiTableRow-head {
        background-color: #FAFBFC;
    }
    .MuiTableCell-head {
        padding: 18px 16px;
        color: rgba(0, 0, 0, 0.54);
        font-weight: 700;
    }
    .MuiTableCell-body {
        padding: 10px 16px;
    }
}
a {
    color: #007AFF;
    text-decoration: none;
}
.contentGrid {
    padding: 30px 22px;
    position: relative;
}
.textInputRight {
    .MuiInputBase-input {
        text-align: right;
    }
}
.dateRight {
    display: flex;
    align-items: center;
    .MuiInputBase-root {
        max-width: 102px;
    }
    .arrow {
        padding-left: 3px;
        padding-right: 5px;
    }
}
.monthСapital {
    .MuiTab-wrapper {
        text-transform: capitalize !important;
    }
}
.autocomplate {
    min-width: 200px;
}
.organization {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__content {
        display: flex;
        align-items: center;
    }
}
.title {
    font-size: 20px;
    font-weight: 600;
}
.line {
    color: #ccc;
}
.textFieldRow {
    display: flex;
    align-items: center;
    margin-top: 14px;
    margin-bottom: 14px;
    strong {
        margin-right: 8px;
    }
    .MuiTextField-root {
        margin-right: 12px;
    }
}
.edit-phone {
    margin-top: 16px;
    margin-bottom: 12px;
}
@media only screen and (max-width: 600px) {
    body {
      background-color: lightblue;
    }
}


 .alertDialog {
   color: #333333;

   .MuiPaper-root {
     padding: 22px 24px 30px;
     max-width: 422px;
     width: 100%;
     box-sizing: border-box;
     text-align: center;
   }

   .alertDialog__close {
     position: absolute;
     right: 12px;
     top: 12px;

     .MuiSvgIcon-root {
       font-size: 24px;
       color: #333333;
     }
   }

   &__title {
     font-size: 24px;
     font-weight: 600;
     margin-bottom: 20px;
   }

   &__question {
     font-size: 12px;
     color: #333333;
     margin-bottom: 32px;
   }

   &__error {
     color: red;
     margin-bottom: 15px;
   }

   &__name {
     font-size: 20px;
     font-weight: 600;
     margin-bottom: 30px;
   }

   &__group {
     .MuiButtonBase-root {
       margin-left: 6px;
       margin-right: 6px;
     }
   }
 }
