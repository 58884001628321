.personCard {
    display: flex;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 18px;
    margin-bottom: 50px;
    margin-top: 10px;
    max-width: 877px;
    box-sizing: border-box;
    &__item {
        padding: 12px;
    }
}
.personBox {
    display: flex;
    margin-left: -12px;
    margin-right: -12px;
    &__item {
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        flex-wrap: wrap;
    }
}
.personalData {
    max-width: 314px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 22px;
    &__typography {
        padding-left: 20px;
        color: #000000;
    }
}
.personalNumberBox {
    display: flex;
    width: 100%;
    align-items: center;
    .MuiFormControl-root {
        max-width: 168px;
        margin: auto;
        margin-top: 15px;
    }
    .MuiInputBase-root {
        border-radius: 8px;
        color: #000000;
    }
    .pr1 {
        padding-right: 5px;
    }
}
.passwordImg {
    max-width: 200px;
    width: 100%;
    display: block;
    height: auto;
}
.userPhoto {
    max-width: 205px;
    width: 100%;
    img {
        max-width: 100%;
        height: auto;
        display: block;
    }
}