.authorization {
    position: fixed;
    top: 0;
    left: 0;
    background: #E5E5E5;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    .MuiInputBase-input {
        font-family: 'Open Sans', sans-serif;
    }
    &__content {
        max-width: 362px;
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 56px 50px;
    }
    &__text {
        font-size: 10px;
        font-weight: 600;
        color: #9D9D9D;
        margin-bottom: 22px;
    }
    &__title {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 18px;
        color: #000;
        text-align: center;
        margin-bottom: 32px;
    }
    &__logo {
        max-width: 132px;
        margin: auto;
        text-align: center;
        margin-bottom: 12px;
        svg {
            width: 100%;
            height: auto;
            display: block;
        }
    }
    &__form {
        margin: auto;
        text-align: center;
        .MuiInputLabel-root {
            right: 0;
            text-align: center;
        }
        .MuiInputLabel-shrink {
            transform: translate(0, 0) scale(1);
            right: 0;
            text-align: center;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.54);
            font-weight: 600;
            &.Mui-focused {
                color: #FC6201;
            }
        }
        .MuiInputBase-root {
            &.Mui-focused {
                &:after,
                &:before {
                    border-color: #FC6201 !important;
                }
            }
        }
        .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-color: #FC6201 !important;
        }
        .MuiInputBase-input {
            text-align: center;
        }
        .MuiFormHelperText-root {
            font-size: 13px;
            text-align: center;
            left: 0;
            right: 0;
            padding-top: 5px;
            color: #31C85A;
        }
        .MuiButton-containedPrimary {
            background-color: #31C85A;
            font-weight: 400;
            padding-left: 36px;
            padding-right: 36px;
            margin-top: 20px;
        }
        .MuiButtonBase-root {
            max-width: 114px;
            width: 100%;
        }
    }
    .react-code-input {
        input {
            box-shadow: none !important;
            border-radius: 0 !important;
            width: 42px !important;
            padding: 0 !important;
            text-align: center;
        }
    }
    &__wrap {
        margin-right: 1rem;
        z-index: 9;
    }
}
.bankLogo {
    max-width: 140px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: auto;
    margin-bottom: 24px;
    img {
        width: 100%;
        display: block;
        height: auto;
    }
}
.ihenim {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: 2.4rem;
    &__content {
        margin-left: 30px;
    }
    &__title {
        font-size: 57px;
        line-height: 1;
    }
    &__label {
        font-size: 29px;
        line-height: 1.2;
    }
}