.flex-wrapper {
    display: flex;
    flex-flow: row nowrap;
}
.single-chart {
    width: 144px;
    justify-content: space-around ;
}

.circular-chart {
    display: block;
}

.circle-bg {
    fill: none;
    stroke: #E6F3FF;
    stroke-width: 2;
}

.circle {
    fill: none;
    stroke-width: 2;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}

.circular-chart .circle {
    stroke: #BE53EB;
}

.percentage {
    fill: #323641;
    font-size: 0.35em;
    font-weight: 600;
    text-anchor: middle;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}