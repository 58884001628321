.personCard {
    display: flex;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 16px;
    margin-bottom: 50px;
    margin-top: 10px;
    max-width: 914px;
    box-sizing: border-box;
    &__item {
        padding: 12px;
    }
}
.personBox {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    &__item {
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        width: 50%;
        box-sizing: border-box;
    }
    &__fullWidth {
        max-width: 628px;
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }
}
.personalData {
    max-width: 314px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 22px;
    &__typography {
        padding-left: 20px;
        color: #000000;
    }
}
.personalNumberBox {
    display: flex;
    width: 100%;
    align-items: center;
    .MuiFormControl-root {
        max-width: 168px;
        margin: auto;
        margin-top: 15px;
    }
    .MuiInputBase-root {
        border-radius: 8px;
        color: #000000;
    }
}